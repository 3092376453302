export class RoutesPath {
  static readonly LOGIN_STORE = 'authentification';
  static readonly PROMO = 'promotion';
  static readonly LOGIN_KITCHENER = 'authentification-cuisiniste';
  static readonly CUSTOMER = 'conso';
  static readonly HOUSEHOLD_COMPOSITION = 'mon-foyer';
  static readonly DASHBOARD = '';
  static readonly YOUR_PROJECT = 'votre-projet';
  static readonly YOUR_REQUIREMENTS = 'vos-besoins';
  static readonly YOUR_EQUIPMENTS = 'vos-equipements';
  static readonly YOUR_SERVICES = 'vos-services';
  static readonly YOUR_LAYOUT = 'votre-implantation';
  static readonly YOUR_STYLE = 'votre-style';
  static readonly GET_IN_TOUCH = 'comment-vous-joindre';
  static readonly PROJECT = 'projet';
  static readonly PREPARE_THE_MEAL = 'preparez-vos-repas';
  static readonly REQUIREMENTS_DINING_AREA = 'le-coin-repas';
  static readonly KITCHEN_OTHER_ACTIVITIES = 'autres-activities-dans-la-cuisine';
  static readonly KITCHEN_STORAGE = 'le-stockage';
}
